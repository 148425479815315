import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import { mfaChallenge } from 'store/modules/login';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    height: '100vh'
  }
}));

export default function MFAChallengeForm({ info }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const error = useSelector((state) => state.login.error);
  const mfaDetails = useSelector((state) => state.login.mfaDetails);
  const [inputCode, setInputCode] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(mfaChallenge({ ...mfaDetails, code: inputCode }));
  };

  return (
    <div className={classes.paper}>
      <Typography component="h1" variant="h5">
        Confirm access with your MFA device
      </Typography>
      <form className={classes.form} noValidate onSubmit={handleSubmit}>
        {error && (
          <Alert className={classes.errorAlert} severity="error">
            {error}
          </Alert>
        )}
        <TextField
          value={inputCode || ''}
          onChange={(e) => setInputCode(e.target.value.trim())}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="mfa_code"
          label="MFA Code"
          name="mfa_code"
          autoComplete="mfa_code"
          autoFocus
        />
        <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
          Confirm
        </Button>
      </form>
    </div>
  );
}
