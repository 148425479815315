import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { useRouter } from 'next/router';
import Quantico from '../public/images/logo.webp';
import SignInForm from 'components/Auth/SignInForm';
import MFASetupForm from 'components/Auth/MFASetupForm';
import MFAChallengeForm from 'components/Auth/MFAChallengeForm';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.quanticoenergy.com/">
        Quantico Energy Solutions
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    height: '100vh'
  },
  logoSide: {
    width: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#266798'
  },
  logoImg: {
    width: '100%',
    maxWidth: '500px',
    height: 'auto'
  },
  paperWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  errorAlert: {
    alignItems: 'center'
  },
  messageAlert: {
    alignItems: 'center'
  }
}));

export default function Login() {
  const classes = useStyles();
  const router = useRouter();
  const [page, setPage] = useState('signin');
  const [userMessage, setUserMessage] = useState(null);
  const login = useSelector((state) => state.login);

  useEffect(() => {
    if (login.loggedIn) {
      router.push('/qearth');
    } else if (login.mfaSetup) {
      setUserMessage(null)
      setPage('mfaSetup');
    } else if (login.mfaChallenge) {
      setUserMessage(null)
      setPage('mfaChallenge');
    } else {
      setPage('signin');
    }
  }, [login]);

  return (
    <Container maxWidth="xl" disableGutters component="main" className={classes.container}>
      <CssBaseline />
      <div className={classes.logoSide}>
        <img src={Quantico} alt="Quantico Energy Solutions" className={classes.logoImg} />
      </div>
      <Container maxWidth="xs" className={classes.paperWrapper}>
        {userMessage && (
          <Alert className={classes.messageAlert} severity="success">
            {userMessage}
          </Alert>
        )}
        {page == 'signin' && <SignInForm reset_mfa_token={router.query.reset_mfa_token} />}
        {page == 'mfaSetup' && <MFASetupForm />}
        {page == 'mfaChallenge' && <MFAChallengeForm />}
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </Container>
  );
}
