import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import { mfaSetup } from 'store/modules/login';
import { useDispatch, useSelector } from 'react-redux';
import QRCode from 'react-qr-code';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    height: '100vh'
  }
}));

export default function MFASetupForm() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const error = useSelector((state) => state.login.error);
  const loginDetails = useSelector((state) => state.login.loginDetails);
  const mfaDetails = useSelector((state) => state.login.mfaDetails);
  const [inputCode, setInputCode] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(
      mfaSetup({
        username: mfaDetails.username,
        accessToken: loginDetails.access,
        code: inputCode
      })
    );
  };

  const qrLink = `otpauth://totp/QApp?secret=${mfaDetails.setup_mfa_code}`;

  return (
    <div className={classes.paper}>
      <Typography component="h1" variant="h5">
        Scan the following QR to link your MFA device
      </Typography>
      <form className={classes.form} noValidate onSubmit={handleSubmit}>
        {error && (
          <Alert className={classes.errorAlert} severity="error">
            {error}
          </Alert>
        )}
        <div style={{ height: 'auto', margin: '0 auto', maxWidth: 200, width: '100%' }}>
          <QRCode
            size={256}
            style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
            value={qrLink}
            viewBox="0 0 256 256"
          />
        </div>

        <TextField
          value={inputCode || ''}
          onChange={(e) => setInputCode(e.target.value.trim())}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="mfa_code"
          label="MFA Code"
          name="mfa_code"
          autoComplete="mfa_code"
          autoFocus
        />
        <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
          Confirm
        </Button>
      </form>
    </div>
  );
}
