import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { login as performLogin } from 'store/modules/login';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    height: '100vh'
  },
  logoSide: {
    width: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#266798'
  },
  logoImg: {
    width: '100%',
    maxWidth: '500px',
    height: 'auto'
  },
  paperWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  errorAlert: {
    alignItems: 'center'
  }
}));

export default function SignInForm({ reset_mfa_token }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const login = useSelector((state) => state.login);
  const error = useSelector((state) => state.login.error);
  const [inputEmail, setInputEmail] = useState(null);
  const [inputPassword, setInputPassword] = useState({ password: null, showPassword: false });
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    const data = new FormData(event.target);
    const email = data.get('email').toLowerCase();
    const password = data.get('password');
    if (email && password) {
      dispatch(performLogin({ email, password, reset_mfa_token }));
    }
  };

  const emptyEmail = submitted && !inputEmail ? 'Email is required' : null;
  const emptyPassword = submitted && !inputPassword.password ? 'Password is required' : null;

  const handlePassword = (prop) => (event) => {
    setInputPassword({ ...inputPassword, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setInputPassword({ ...inputPassword, showPassword: !inputPassword.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className={classes.paper}>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      <form className={classes.form} noValidate onSubmit={handleSubmit}>
        {error && (
          <Alert className={classes.errorAlert} severity="error">
            {error}
          </Alert>
        )}
        <TextField
          value={inputEmail || ''}
          onChange={(e) => setInputEmail(e.target.value.trim())}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          error={!!emptyEmail}
          helperText={emptyEmail}
        />
        <TextField
          value={inputPassword.password || ''}
          onChange={handlePassword('password')}
          margin="normal"
          required
          fullWidth
          name="password"
          variant="outlined"
          type={inputPassword.showPassword ? 'text' : 'password'}
          id="password"
          label="Password"
          autoComplete="current-password"
          error={!!emptyPassword}
          helperText={emptyPassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {inputPassword.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
          Sign In
        </Button>
        <Grid container>
          <Grid item xs>
            <Link href="/forgot-password">Forgot password?</Link>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
